.pictureContainer {
  display: flex;
  position: relative;
  margin-right: 2%;
}

.profileImage {
  display: flex;
  width: 100%;
  max-width: 100px !important;
}
