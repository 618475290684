.teacherProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
}

.backToResults {
  display: flex;
  color: #0080bf;
  cursor: pointer;
  border-radius: 4px;
  background-color: white;
  border: 0px;
  padding: 1% 3%;
  font-weight: 600;
  align-self: flex-start;
}

.backToResults:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.teacherInfo {
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2%;
  margin-top: 2%;
}

.teacherChip {
  display: flex;
  margin-right: 1%;
}

.teacherInfo p {
  margin: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
}

.teacherCardHeader {
  display: flex;
  align-items: center;
}

.teacherCardHeader img {
  max-width: 60px;
  border-radius: 50%;
  margin-right: 2%;
}

.teacherCardName {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--blue-600);
  font-weight: 600 !important;
}

.teacherTopCard {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-left: auto;
  margin-top: 3%;
  color: var(--blue-600);
}

.teacherTopCard h4 {
  margin: 0;
  color: #1DA1F2;
  font-weight: 600;
  font-size: 18px;
  text-align: right;
}

.teacherDescription {
  text-align: left;
  margin: 15px 0 20px 0;
  color: var(--blue-600);
  max-height: 200px;
  overflow: auto;
}

.teacherCoursesSeparator {
  border-top: 1px solid #00000038;
  padding-top: 10px;
}


.priceTitle {
  display: flex;
}

.selectedCourse {
  background: #73bddf;
}

.teacherProfilebyHour {
  color: grey;
  font-size: small;
}

.teacherChips {
  display: flex;
  margin-top: 1rem;
  color: #0080bf;
}

.configValue {
  color: grey;
  font-size: 14px;
}

@media (max-width: 768px) {
  .teacherCardHeader {
    display: flex;
    flex-direction: column;
  }

  .teacherChips {
    justify-content: center;
  }

  .teacherCardHeader img {
    margin-right: 0;
  }

  .teacherTopCard {
    margin-left: 0;
    align-items: center;
  }

  .teacherProfilebyHour {
    font-style: normal;
  }

  .teacherProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .backToResults {
    align-self: flex-start;
  }
}
