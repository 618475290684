.carousel {
  position: relative;
  overflow: hidden;
}

.carousel-viewport {
  display: flex;
  scroll-behavior: smooth;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  align-items: stretch;
}

.carousel-viewport::-webkit-scrollbar {
  display: none;
}

.carousel-arrow {
  display: none;
}

@media (min-width: 512px) {
  .carousel-arrow {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: var(--blue-100);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, opacity 0.3s;
  }

  .carousel-arrow.left {
    left: 10px;
  }

  .carousel-arrow.right {
    right: 10px;
  }

  .carousel-arrow:hover {
    background: var(--blue-300);
  }

  .carousel-arrow:disabled {
    display: none;
  }
}
