.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(80vh);
  background: linear-gradient(#51a2ca, #184b76);
  color: white;
}

.home h1 {
  font-size: 50px;
  text-shadow: 1px 1px #002338;
  font-family: 'Estudi Font';
}

.homeTitle {
  display: flex;
  align-items: center;
  font-size: var(--fs-xl);
  margin-bottom: 20px;
  line-height: 1.9em;
  letter-spacing: .06em;
}

.highlight-container, .highlight {
  position: relative;
}

.highlight-container {
  display: inline-block;
  margin: 0 10px;
}

.highlight-container:before {
  background-color: #57bfa3;

  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 60%;
  left: -2px;
  bottom: 0;
  transform: rotate(-2deg);
}

.searchFilters {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: black;
  height: 200px
}

.searchButton {
  color: white;
  cursor: pointer;
  border-radius: 4px;
  background-color: #57bfa3;
  border: 0px;
  padding: 10px 25px;
  font-weight: 600;
  width: 20vw;
}

.searchButton:hover {
  background-color: #3ea78b;
}

.searchButton:disabled {
  opacity: 45%;
  cursor: not-allowed;
}

.helpLink {
  font-size: 14px;
  margin-bottom: 2rem;
}

.home a {
  color: #3ea78b;
}

.select {
  width: 20vw;
}

.noInstitution {
  font-size: 14px;
  text-align: center;
}


@media (max-width: 768px) {
  .select {
    width: 50vw;
  }

  .searchButton {
    width: 50vw;
  }

  .home {
    justify-content: space-evenly;
  }

  .homeTitle {
    font-size: var(--fs-lg);
  }

  .highlight-container:before {
    height: 65%;
  }

  .searchFilters {
    justify-content: space-evenly;
    height: 280px
  }

  .highlight-container:before {
    height: 100%;
  }
}
