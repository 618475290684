.resultCard {
  text-decoration: none;
  /* margin-right: 2%;
  margin-bottom: 2%;
  width: 45%;
  max-width: 500px; */
}

.byHour {
  font-style: italic;
  color: grey;
}

.resultCardName {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--blue-600);
  font-weight: 600 !important;
}

.resultCardHeader {
  display: flex;
  align-items: center;
}

.resultCardHeader img {
  max-width: 60px;
  border-radius: 50%;
  margin-right: 2%;
}

.resultCardPrice {
  margin-left: auto;
  color: var(--blue-600);
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.resultCardPrice h4 {
  margin-left: auto;
  margin: 0;
  color: #1DA1F2;
  font-weight: 600;
  font-size: 18px;
}

.availability {
  padding: 0;
  margin: 10px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  gap: 10px;
  list-style: disc inside none;
}

.profileDescription {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.resultCard p {
  text-align: left;
}

.availabilityDay {
  list-style-type: none;
  line-height: 2em;

  display: list-item;
  list-style: none;
}

.availabilityDay span {
  border: 1px solid #EEEEEE;
  background: #F7F8FC;
  color: rgba(0, 0, 0, 0.55);
  border-radius: 3px;
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  height: 30px;
  justify-content: center;
  align-items: center;
  word-break: break-word;
}

.availabilityDayEnabled {
  background: #f0faf3 !important;
  color: #2C7B63 !important;
  border: 1px solid rgba(44, 123, 99, 0.15) !important;
}

@media (max-width: 1120px) {
  .resultCard {
    max-width: unset;
    width: 100%;
  }

  .profileDescription {
    text-overflow: clip;
    white-space: normal;
    overflow: visible;
  }

  .availability {
    grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
  }
}
