.reservationForm {
  display: flex;
  flex-direction: column;
  margin: 5%;
}

.studentNameInformation {
  display: flex;
}

.reservationFormButtons {
  display: flex;
  margin-top: 5%;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
}

.termsButton {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.8rem;
}

.groupClasses {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-bottom: 10px;
}

.plusFour {
  display: inline-flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-evenly;
}

@media (max-width: 768px) {
  .studentNameInformation {
    display: flex;
    flex-direction: column;
  }

  .reservationFormButtons {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
  }
}
