.navbar {
  display: flex;
  align-items: center;
  background: linear-gradient(45deg,#51a2ca, #184b76);
  color: white;
  justify-content: space-between;
  width: 100%;
}

.sidebarTitle {
  margin: 2%;
  margin-left: 5%;
  font-size: 34px;
  font-weight: bold;
  color: white;
}

.sidebarTitle a {
  display: flex;
  justify-content: center;
  cursor: pointer;
  /*width: calc(100% - 4px);*/
  text-decoration: none;
  color: white;
  font-family: 'Estudi Font';
}

.navLinks {
  display: flex;
  margin-right: 5%;
  align-items: center;
}

.navLink {
  margin-left: 2rem;
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-family: 'Estudi Font';
}

.navLink:hover {
  text-decoration: underline;
}

.drawer {
  display: none;
}

@media (max-width: 768px) {
  .navLinks {
    display: none;
  }

  .navLink {
    display: none;
  }

  .drawer {
    display: flex;
  }

  .drawerLink {
    cursor: pointer;
    text-decoration: none;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: var(--fs-xs);
    line-height: var(--lh-sm);
    width: 100%;
    min-width: 180px;
    font-family: 'Estudi Font';
  }

  .drawerLink:hover {
    text-decoration: underline;
  }
}