@font-face {
  font-family: 'Estudi Font';
  src: url('resources/fonts/MusticaPro-SemiBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
