.rate {
  display: flex;
  align-items: center;
  margin-top: 0%;
}

.rateValue {
  color: grey;
  font-size: 14px;
}

@media (max-width: 768px) {
  .rate {
    margin-top: 2%;
    justify-content: center;
  }
}
