.help {
  margin-top: 4%;
  min-height: calc(100vh);
}

.helpContainer {
  display: flex;
  flex-direction: column;
  margin: 1%;
  margin-top: 3%;
}

.helpTitle {
  text-align: left;
  margin-left: 3%;
  font-size: var(--fs-xl);
  color: var(--blue-300);
  font-weight: 600;
}

.helpButtons {
  margin: 2%; 
  background-color: #51a2ca33;
  border-radius: 5px;
  color: #184b76;
}

.helpButtons a {
  text-decoration: none;
  color: inherit;
}

.helpItem {
  display: flex;
  padding: 2%;
  justify-content: space-between;
}

.helpButtons .helpItem {
  border-bottom: 1px solid lightgray;
}

.helpButtons > *:last-child .helpItem{
  border-bottom: none;
}

.helpItem:hover {
  background-color: #184b76;
  color: white;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .helpItem {
    padding: 5%;
  }
}
