:root {
  --carousel-visible-width: 0.35;
}

.carouselItem {
  max-width: 30rem;
  flex: 0 0 calc(var(--carousel-visible-width) * 100%);
  scroll-snap-align: start;
  transition: transform 0.3s ease-in-out;
  margin: 1rem;
}

.carouselItem:hover {
  transform: scale(1.05);
}
