
.landing-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
}

.landing-content {
  display: flex;
  flex-direction: column;
  color: white;
}

.landing-image {
  display: flex;
  margin: 2%;
}

.landing-mobile-image {
  display: none;
  margin: 2%;
}

.about {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1%;
}

.about h1 {
  color: #51a2ca;
}

.about span {
  margin-left: 5%;
  margin-right: 5%;
}

.landing-title {
  margin-bottom: 1rem;
}

.landing-container h1 {
  font-size: 100px;
  text-shadow: 1px 1px #002338;
  font-family: 'Estudi Font';
  margin: 0;
}

.landing-container h2 {
  color: #59bea5;
  font-family: 'Estudi Font';
  margin: 0;
}

@media (max-width: 768px) {
  .landing-container {
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
  }
  .landing-container h1 {
    font-size: 80px;
  }

  .landing-image {
    display: none;
  }
  .landing-mobile-image {
    display: flex;
  }
}

.call-to-action {
  display: flex;
  justify-content: center;
}

.top-cta-button {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.bottom-cta-button {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
