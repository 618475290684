.editTeacherProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
}

.teacherPersonalInfo,
.teacherConfiguration,
.teacherDictatedCourses {
  margin: 5%;
  width: 100%;
  margin-top: 2%;
}

.teacherPersonalInfo,
.teacherConfiguration,
.teacherDictatedCourses p {
  margin: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
}

.teacherCardHeader {
  display: flex;
  align-items: center;
}

.teacherCardHeader img {
  max-width: 60px;
  border-radius: 50%;
  margin-right: 2%;
}

.teacherCardName {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--blue-600);
  font-weight: 600 !important;
}

.editForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.editCoursesForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 768px) {
  .teacherCardHeader {
    display: flex;
    flex-direction: column;
  }

  .teacherChips {
    justify-content: center;
  }

  .teacherCardHeader img {
    margin-right: 0;
  }

  .editTeacherProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .teacherPersonalInfo,
  .teacherConfiguration,
  .teacherDictatedCourses {
    max-width: 80%;
  }
}
