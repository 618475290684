
.schools-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
}

.schools-content {
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  align-items: center;
}

.schools-about {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1%;
}

.schools-about h1 {
  color: #51a2ca;
}

.schools-title {
  margin-bottom: 1rem;
}

.schools-subtitle {
  margin-top: -20px;
  display: flex;
  align-self: end;
  justify-content: flex-end;
}

.schools-container h1 {
  font-size: 100px;
  text-shadow: 1px 1px #002338;
  font-family: 'Estudi Font';
  margin: 0;
}

.schools-container h2 {
  color: #59bea5;
  font-family: 'Estudi Font';
  margin: 0;
}

@media (max-width: 768px) {
  .schools-container {
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
  }
  .schools-container h1 {
    font-size: 80px;
  }
}
