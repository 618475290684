.footer {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-900);
  color: var(--white-500);
  padding: 2rem 1rem 1rem;
  z-index: 3;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.footer-column {
  text-align: center;
  font-size: var(--fs-xs);
  line-height: var(--lh-2xs);
  min-width: 15rem;
}

.footer-logo {
  color: #fff;
  font-size: var(--fs-xl);
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: 'Estudi Font';
  text-shadow: 1px 1px #002338;
}

.footer-column h4 {
  color: #fff;
  font-size: var(--fs-xs);
  line-height: var(--lh-2xs);
  font-weight: 600;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  margin-bottom: 0.5rem;
  font-size: var(--fs-2xs);
}

.footer-column ul li a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s;
}

.footer-column ul li a:hover {
  color: #fff;
}

.social-icons a {
  transition: color 0.2s;
}

.footer-bottom {
  border-top: 1px solid #2d3748;
  margin-top: 2rem;
  text-align: center;
  font-size: var(--fs-2xs);
  line-height: var(--lh-3xs);
}

@media (max-width: 768px) {
  .footer {
    padding: 1rem;
  }

  .footer-container {
    flex-direction: column;
    gap: 0.5rem;
    margin: 0 1rem;
  }

  .footer-column {
    text-align: left;
    font-size: var(--fs-2xs);
    line-height: var(--lh-3xs);
  }

  .footer-column h4 {
    font-size: var(--fs-xs);
    line-height: var(--lh-2xs);
  }
}
