.loginPage {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh);
  width: 100%;
  background-color: #f3f3f3;
  justify-content: flex-start;
  align-items: center;
}

.loginCard {
  margin-top: 10%;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  max-width: 21rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.loginCard h2 {
  text-align: center;
  margin-bottom: 1rem;
  font-family: 'Open Sans', sans-serif;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.loginInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
  border: 1px solid #ccc;
  border-radius: 4px;
}

.loginButton {
  width: 100%;
}

.ssoLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ssoLogin p {
  margin: 20px 0 10px 0;
}

.googleLoginButton {
  width: 100%;
  display: flex;
  justify-content: center;
}

.authError {
  color: red;
  margin-bottom: 15px;
  text-align: center;
}

@media (max-width: 768px) {
  .loginCard {
    width: 90%;
    padding: 1rem;
    max-width: 80%;
  }

  .loginInput {
    font-size: var(--fs-2xs);
    line-height: var(--lh-2xs);
  }
}
