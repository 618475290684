.privacy {
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.privacy h2 {
  margin-top: 2rem;
}


@media (max-width: 768px) {
  .privacy {
    padding: 1.5rem 1.5rem;
  }

  .privacy h2 {
    margin-top: 1.5rem;
  }
}