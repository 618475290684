.teacherRequest {
  margin-top: 4%;
}

.teacherRequestContainer {
  display: flex;
  flex-direction: column;
}

.teacherRequestTitle {
  text-align: center;
  margin-left: 3%;
  font-size: 24px;
  color: #184b76;
  font-weight: 600;
}

.teacherRequestForm {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 7%;
  }

.teacherRequestFormButton {
    display: flex;
    margin-top: 5%;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px
  }

  .teacherRequestSuccess {
    display: flex;
    margin: auto;
  }

  .teacherRequestSuccessTitle {
    color: #184b76;
    margin-bottom: 10%;
  }
  
  .teacherRequestSuccessSummary {
    max-width: 600px;
  }
  
  .teacherRequestSuccessContact {
    margin-top: 10%;
  }
  
  @media (max-width: 768px) {
    .teacherRequestFormButton {
      display: flex;
      flex-direction: column-reverse;
    }

    .teacherRequestSuccess {
      margin-left: 8%;
      margin-right: 8%;
    }
  }
  