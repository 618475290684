
.reservationInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: left;
  color: rgb(82, 77, 77);
  border: 1px solid #00000038;
  border-left: 0px;
  border-right: 0px;
  padding: 1%;
  margin: 5%;
  align-items: center;
}

.reservationInfo span {
  font-size: smaller;
}

.reservationSub {
  font-style: italic;
}
.reservationInfoHeader {
  font-weight: 400;
  padding-right: 20px;
}

.checkoutSpinner {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
}

.checkoutSpinnerText {
  font-size: x-large;
  color: #184b76;
  margin-bottom: 5%;
}

.congratDescription {
  margin-left: 1%;
  margin-right: 1%;
}

.checkoutButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
