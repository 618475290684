.terms {
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.terms h2 {
  margin-top: 2rem;
}


@media (max-width: 768px) {
  .terms {
    padding: 1.5rem 1.5rem;
  }

  .terms h2 {
    margin-top: 1.5rem;
  }
}