.results {
  display: flex;
  text-align: center;
  min-height: calc(100vh);
  width: 100%;
}

.teachers {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f3f3f3;
  flex-grow: 1;
  padding: 5%;
}

.teachers > * {
  margin-bottom: 2%;
}

.resultsInfoSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
}

.resultsCount {
  display: flex;
  text-align: left;
  max-width: 50%;
}

.sortBySection {
  display: flex;
  align-items: center;
}

.resultsContent {
  margin-top: 2%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(400px, 100%), 1fr));
  grid-gap: 10px;
}

.resultsBold {
  font-weight: bold;
}

.groupStyles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectComponent {
  display: flex;
  align-items: center;
}

.selectComponentText {
  display: flex;
}

.selectComponentIcon {
  display: none !important;
}

.teacherRequestButton {
  margin: auto;
}

@media (max-width: 768px) {

  .selectComponentText {
    display: none;
  }

  .selectComponentIcon {
    display: flex !important;
  }
}
