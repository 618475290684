.helpPage {
    display: flex;
    flex-direction: column;
    margin-top: 4%;
    min-height: calc(100vh);
}

.pageTitle {
    text-align: left;
    margin-left: 3%;
    font-size: 24px;
    color: #184b76;
    font-weight: 600;
}