.authSpinner {
  display: flex;
  margin: auto;
  width: 80%;
  justify-content: center;
  align-items: center;
  padding: 10%;
  gap: 5%;
}

.authSpinner span {
  font-size: var(--fs-lg);
  line-height: var(--fs-lg);
  color: #184b76;
  margin-bottom: 5%;
}
