.aboutUs {
  padding: 4rem;
  background-color: var(--blue-300);
  position: relative;
  overflow: hidden;
  color: white;
}

.aboutUsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
}

.whyChooseUsTitle {
  color: var(--blue-300);

}

.whyChooseUsTitle,
.sectionTitle {
  font-size: var(--fs-2xl);
  letter-spacing: 0.06em;
  margin: 0;
}

.aboutUsDescription {
  font-size: var(--fs-sm);
}

.highlightContainer, .highlight {
  position: relative;
}

.highlightContainer {
  display: inline-block;
  margin: 0 10px;
}

.highlightContainer:before {
  background-color: var(--green-100);
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 70%;
  left: -2px;
  bottom: 0;
  transform: rotate(-2deg);
}

.whyChooseUs {
  padding: 4rem;
  background-color: var(--gray-100);
}

.whyChooseUsContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.featureCard {
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.featureCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.icon {
  color: var(--blue-300);
}

.featureTitle {
  font-size: var(--fs-sm);
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.featureDescription {
  color: #555;
  font-size: 0.875rem;
}

/* Media Queries */
@media (max-width: 1024px) {
  .featuresGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .aboutUs {
    padding: 3rem 2rem;
  }

  .aboutUsDescription {
    font-size: var(--fs-2xs);
  }

  .whyChooseUs {
    padding: 2rem;
  }

  .whyChooseUsContainer,
  .aboutUsContainer {
    gap: 1rem
  }

  .whyChooseUsTitle,
  .sectionTitle {
    font-size: var(--fs-xl);
  }

  .featuresGrid {
    grid-template-columns: 1fr;
  }
}
