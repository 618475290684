.payment {
  display: flex;
  margin: auto;
}

.paymentTitle {
  color: #184b76;
  margin-bottom: 10%;
}

.paymentSummary {
  max-width: 600px;
}

.paymentContact {
  margin-top: 10%;
}

.paymentBrand {
  font-family: 'Estudi Font';
}

.paymentWaiting {
  font-size: x-large;
  color: #184b76;
  margin-top: 30%;
  padding: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.paymentWaiting span {
  margin-bottom: 20%;
}

@media (max-width: 768px) {
  .payment {
    margin-left: 8%;
    margin-right: 8%;
  }
}
