.grid {
  margin: 3rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  grid-auto-rows: minmax(100px, auto);
}

.numbers-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.numbers-div span {
  font-size: small;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 0.5fr);
    gap: 0.75rem;
    margin: 1.75rem;
  }
}
