/* Base styles */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont;
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
  font-weight: 600;
  border-radius: 0.4rem;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, opacity 0.3s;
  text-decoration: none;
  color: black;
  background-color: var(--white-200);
  border: 2px solid;
}

.button:hover {
  background-color: var(--white-500);
}

.button:disabled {
  opacity: 0.45;
  cursor: not-allowed;
}

/* Contained variant */
.button.contained {
  color: white;
  border-color: transparent;
}

.button.contained.primary {
  background-color: var(--blue-100);
}

.button.contained.primary:hover {
  background-color: var(--blue-300);
}

.button.contained.primary:disabled {
  background-color: var(--blue-100);
  opacity: 0.45;
  cursor: not-allowed;
}

.button.contained.secondary {
  background-color: var(--green-100);
}

.button.contained.secondary:hover {
  background-color: var(--green-200);
}

.button.contained.secondary:disabled {
  background-color: var(--green-100);
}

.button.contained.error {
  background-color: var(--red-100);
}

.button.contained.error:hover {
  background-color: var(--red-300);
}

.button.contained.error:disabled {
  background-color: var(--red-100);
}

/* Outlined variant */
.button.outlined {
  background-color: transparent;
}

.button.outlined.primary {
  border-color: var(--blue-100);
  color: var(--blue-100);
}

.button.outlined.primary:hover {
  background-color: var(--blue-300);
  color: white;
}

.button.outlined.primary:disabled {
  border-color: var(--blue-100);
}

.button.outlined.secondary {
  border-color: var(--green-100);
  color: var(--green-100);
}

.button.outlined.secondary:hover {
  background-color: var(--green-100);
  color: white;
}

.button.outlined.secondary:disabled {
  border-color: var(--green-100);
}

.button.outlined.error {
  border-color: var(--red-100);
  color: var(--red-100);
}

.button.outlined.error:hover {
  background-color: var(--red-100);
  color: white;
}

.button.outlined.error:disabled {
  border-color: var(--red-100);
}

/* Text variant */
.button.text {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
}

.button.text:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.button.text.primary {
  color: var(--blue-100);
}

.button.text.primary:hover {
  color: var(--blue-300);
}

.button.text.secondary {
  color: var(--green-100);
}

.button.text.secondary:hover {
  color: var(--green-200);
}

.button.text.error {
  color: var(--red-100);
}

.button.text.error:hover {
  color: var(--red-300);
}

.button.text:disabled {
  color: rgba(0, 0, 0, 0.45);
}

/* Size customization */
.button.size-small {
  font-size: var(--fs-3xs);
  line-height: var(--lh-3xs);
  min-height: 1.2rem;
  min-width: 7rem;
}

.button.size-medium {
  font-size: var(--fs-2xs);
  line-height: var(--lh-2xs);
  min-height: 1.5rem;
  min-width: 9rem;
}

.button.size-large {
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
  min-height: 1.8rem;
  min-width: 11rem;
}

/* Padding customization */
.button.padding-small {
  padding: 0.3rem 0.8rem;
}

.button.padding-medium {
  padding: 0.5rem 1rem;
}

.button.padding-large {
  padding: 1rem 2rem;
}

@media (max-width: 512px) {
  .button {
    border: 1px solid;
  }

  /* Size customization mobile */
  .button.size-small {
    min-height: 1rem;
    min-width: 5rem;
  }

  .button.size-medium {
    min-height: 1.2rem;
    min-width: 7rem;
  }

  .button.size-large {
    min-height: 1.5rem;
    min-width: 9rem;
  }

  /* Padding customization mobile */
  .button.padding-small {
    padding: 0.2rem 0.6rem;
  }

  .button.padding-medium {
    padding: 0.4rem 0.8rem;
  }

  .button.padding-large {
    padding: 0.8rem 1.6rem;
  }
}
