.errorPage {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(#51a2ca, #184b76);
  height: 100vh;
  justify-content: center;
  color: white;
}

.errorPage h1 {
  font-size: xxx-large;
}

.backHomeButton {
  color: white;
  cursor: pointer;
  border-radius: 4px;
  background-color: #57bfa3;
  border: 0px;
  padding: 10px 25px;
  font-weight: 600;
  width: 150px;
}
