.teacherSelectedCourse {
  border: 1px solid #3b6c83;
  background: #F7F8FC;
  border-radius: 3px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  padding: 3px 12px;
  color: #3b6c83;
  margin: 10px 0;
}

.teacherCourses {
  border: 1px solid #3b6c83;
  background: #F7F8FC;
  border-radius: 3px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  padding: 3px 12px;
  color: #3b6c83;
}

.dictatedCourses {
  padding: 0;
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  list-style: disc inside none;
}
