.tutorial-container {
  display: flex;
  flex-direction: column;
}

.tutorial-container h1 {
  display: flex;
  justify-content: center;
  color: #51a2ca
}

.tutorial {
  margin: 3rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  grid-auto-rows: minmax(100px, auto);
}

.faq {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .faq {
    flex-direction: column;
  }
}