.recentCourseCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--white-100);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
  transition: box-shadow 0.3s ease;
}

.recentCourseCard:hover {
  box-shadow: var(--shadow-md);
}

.cardContent {
  padding: var(--spacing-4);
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.courseName {
  font-size: var(--fs-xs);
  font-weight: bold;
  margin: 0;
}

.institutionName {
  font-size: var(--fs-2xs);
  color: var(--gray-500);
  margin: 0;
}

.teacherInfo {
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
}

.teacherLabel {
  font-size: var(--fs-2xs);
  color: var(--gray-400);
  margin: 0;
}

.teacherName {
  font-size: var(--fs-2xs);
  font-weight: bold;
  margin: 0;
}

.cardActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-4);
  background: var(--gray-100);
  border-top: 1px solid var(--gray-200);
  gap: 0.5rem;
}
