.loaderSpinner {
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
}
 
.loaderSpinner span {
    font-size: x-large;
    color: #184b76;
    margin-bottom: 5%;
}
  