.outline {
  display: flex;
  flex-direction: column;
  text-align: center;
}

@media (max-width: 768px) {
  .outline {
    flex-direction: column;
  }
}
