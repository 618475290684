.complaintForm {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 7%;
  }

.complaintFormButton {
    display: flex;
    margin-top: 5%;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px
  }
  
  @media (max-width: 768px) {
    .complaintFormButton {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  